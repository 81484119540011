import React from 'react'
import { Link } from 'gatsby'

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const CTAStart = () => {

  return (
    <div className="rbs-section">
      <div className="rbs-box-a bg-rbs-red text-white">
        <Link to="/start" className="block content">
          <p className="title-xl mb-4">Start Now &rarr;</p>
          <p className="body-l">
            Join a free 1hr brand strategy workshop
            </p>
        </Link>
      </div>

      

    </div>
  )
}

export default CTAStart;
